<template>
  <div>
    <v-card
      elevation="0"
      class="py-7 px-0 px-xl-16 px-lg-16 px-md-16 px-sm-16 px-xs-16"
    >
      <v-card-title class="home-text pb-2">
        <h1 class="mb-3">HOME</h1>
      </v-card-title>
    </v-card>

  </div>
</template>
<script>

import swal from "@/mixins/swal";

export default {
  mixins: [swal],
  name: "Home",
  data() {
    return {
    }
  },
  watch: {

  },
  methods: {
  },
};
</script>
<style scoped>
.div-logo{
 width: 40px;
    height: 24px;
    margin: 10px;
    background-color: #fff;
}
.v-btn {
  text-transform: none !important;
}
.home-text {
  text-align: center;
}
</style>
